<script setup>
import {ref} from 'vue';
import {useForm} from "@inertiajs/inertia-vue3";

const props = defineProps({
    agencyInfo : {
        type     : Object,
        required : true
    },
});

const formData = useForm({
    email_blasts_auto_approve: props.agencyInfo.email_blasts_auto_approve,
});
const data = ref({
    email_blast_auto_approve_changed: false
});

const loading = ref(false);

const updateSettings = function(){
  loading.value = true;
  formData.put(route('agencies.email-and-text-settings', {agency: props.agencyInfo.id}),{
    preserveScroll: true,
    onSuccess: function(){
      $notify.success('Settings updated');
    },
    onError: function(){
      $notify.error('Failed to update settings');
    },
    onFinish: function(){
      loading.value = false;
    }
  });
};
</script>

<template>
    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3 pb-6">
        <!-- BEGIN: Auto Approve Email Blast Templates -->
        <form @submit.prevent="submitEmailBlastAutoApprovalSave" class="col-span-12 grid grid-cols-12 gap-3 lg:gap-6" style="align-items: end">
            <div class="col-span-12 lg:col-span-3 input-form" :class="{'has-error': formData.errors.email_blasts_auto_approve}">
                <label for="email_blasts_auto_approve" class="form-label  text-gray-600">Email Blasts: Auto Approve Templates</label>
                <select v-model="formData.email_blasts_auto_approve" @change="emailBlastsAutoApproveChanged" data-placeholder="Auto approve email blasts" class="input-field w-full" id="email_blasts_auto_approve" name="email_blasts_auto_approve">
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                </select>
                <div class="error-message mt-1" v-if="formData.errors.email_blasts_auto_approve">{{ formData.errors.email_blasts_auto_approve }}</div>
            </div>
        </form>
        <!-- END: Auto Approve Email Blast Templates -->
    </div>

    <div class="mt-4">
      <div class="flex justify-end">
        <button class="btn btn-primary" @click.prevent="updateSettings" :disabled="loading" :class="{'opacity-30': loading}">Update</button>
      </div>
    </div>
</template>

<style scoped>
</style>
