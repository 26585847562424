<template>
  <!-- BEGIN: Agency View -->
  <div class="col-span-12 sm:p-0 p-4">
    <!--  Begin: Header -->
    <div class="border-b sm:p-4 p-0 pb-4 flex space-x-4 items-center justify-between">
      <div class="flex space-x-4 items-center">
        <h4 class="font-bold text-lg">Viewing Agency: <span class="font-bold text-lg">{{ agency_info.name }}</span></h4>
      </div>
    </div>
    <!-- End: Header -->
    <div class="col-span-12 flex items-center justify-between">
        <div class="lg:hidden w-full">
          <label for="tabs" class="sr-only">Select a tab</label>
          <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
          <select @change="setCurrentTab($event.target.value)" id="tabs" name="tabs"
            class="block w-full rounded-md border-gray-300 focus:border-tb-blue focus:ring-tb-blue">
            <option v-for="tab in tabs" :key="tab.key" :value="tab.key" :selected="currentTab === tab.key">{{
              tab.label
            }}
            </option>
          </select>
        </div>
        <div class="whitespace-nowrap hidden lg:block w-full overflow-y-hidden overflow-x-auto">
          <nav class="flex space-x-1 md:rounded p-2 bg-white"
            aria-label="Tabs">
            <a @click.prevent="setCurrentTab(tab.key)" v-for="tab in tabs" :key="tab.key" href="javascript:;"
              :class="[currentTab === tab.key ? 'bg-tb-blue text-white shadow' : 'text-gray-500 hover:text-gray-700', 'px-6 py-2 font-semibold rounded-md']"
              :aria-current="currentTab === tab.key ? 'page' : undefined">{{ tab.label }}</a>
          </nav>
        </div>
      </div>
    <div class="tab-content mt-5 min-h-[300px]">
      <!-- BEGIN: Agency Info  -->
      <div v-if="currentTab === tabs[0].key" :id="tabs[0].key" class="tab-pane leading-relaxed p-2 md:p-5 active" role="tabpanel" :aria-labelledby="tabs[0].key">
        <agency-info
          v-model:agency_info="agency_info"
          :states="states"
          :edit-mode="editMode"
          @show-edit-mode="handleShowEditMode"
          :available_support_persons="available_support_persons"
          :action_schedules="action_schedules"
          :buckets="buckets"
        ></agency-info>
      </div>
      <!-- END: Agency Info  -->
      <!-- BEGIN: Manage Dead Reasons  -->
      <div v-else-if="currentTab === tabs[1].key" :id="tabs[1].key" class="tab-pane leading-relaxed p-5" role="tabpanel" :aria-labelledby="tabs[1].key">
        <agency-manage-dead-reasons :agency-id="agency_info.id"></agency-manage-dead-reasons>
      </div>
      <!-- END: Manage Dead Reasons  -->
      <!-- BEGIN: Manage Bookmarks  -->
      <div v-else-if="currentTab === tabs[2].key || currentTab === 'agency_bookmarks'" :id="tabs[2].key" class="tab-pane leading-relaxed p-5" role="tabpanel" :aria-labelledby="tabs[2].key">
        <agency-manage-bookmarks :agency-id="agency_info.id"></agency-manage-bookmarks>
      </div>
      <!-- END: Manage Bookmarks  -->
      <!-- BEGIN: Manage Lead Tags  -->
      <!--<div v-else-if="currentTab === tabs[3].key" :id="tabs[3].key" class="tab-pane leading-relaxed p-2 md:p-5" role="tabpanel" :aria-labelledby="tabs[3].key">
        <agency-manage-lead-tags :agency-id="agency_info.id"></agency-manage-lead-tags>
      </div>-->
      <!-- END: Manage Lead Tags  -->
      <div v-else-if="currentTab === tabs[3].key" :id="tabs[3].key" class="tab-pane leading-relaxed p-2 md:p-5" role="tabpanel" :aria-labelledby="tabs[3].key">
        <agency-notification
          v-model:agency_info="agency_info"
          :edit-mode="editMode"
          @show-edit-mode="handleShowEditMode"
        ></agency-notification>
      </div>
      <!-- BEGIN: Working Hours  -->
      <div v-else-if="currentTab === tabs[4].key" :id="tabs[4].key" class="tab-pane leading-relaxed p-2 md:p-5" role="tabpanel" :aria-labelledby="tabs[4].key">
        <agency-working-hours :hours="hours" :days="days" :slots="slots" :agency-id="agency_info.id"></agency-working-hours>
      </div>
      <!-- END: Working Hours  -->
      <!-- BEGIN: Stripe Keys  -->
      <div v-else-if="currentTab === tabs[5].key" :id="tabs[5].key" class="tab-pane leading-relaxed p-2 md:p-5" role="tabpanel" :aria-labelledby="tabs[5].key">
        <agency-stripe-keys :stripe_keys="stripe_keys" :agency-id="agency_info.id"></agency-stripe-keys>
      </div>
      <!-- END: Stripe Keys  -->
      <div v-else-if="currentTab === tabs[6].key" :id="tabs[6].key" class="tab-pane leading-relaxed p-2 md:p-5" role="tabpanel" :aria-labelledby="tabs[6].key">
        <agency-department-list-item :agency-id="agency_info.id" :departments="departments"></agency-department-list-item>
      </div>
      <!-- END: Stripe Keys  -->
      <!-- BEGIN: Emails & Texts  -->
      <div v-else-if="currentTab === tabs[7].key" :id="tabs[7].key" class="tab-pane leading-relaxed p-2 md:p-5" role="tabpanel" :aria-labelledby="tabs[7].key">
        <agency-emails-and-texts :agency-info="agency_info"></agency-emails-and-texts>
      </div>
      <!-- END: Email & Text  -->
      <!-- BEGIN: Emails & Texts  -->
      <div v-else-if="currentTab === tabs[8].key" :id="tabs[8].key" class="tab-pane leading-relaxed p-2 md:p-5" role="tabpanel" :aria-labelledby="tabs[8].key">
        <agency-sidebar-and-widgets :agency-info="agency_info" :sidebar_widgets="sidebar_widgets"></agency-sidebar-and-widgets>
      </div>
      <!-- END: Email & Text  -->
      <!-- BEGIN: Twilio Sids  -->
      <div v-else-if="currentTab === tabs[9].key" :id="tabs[9].key" class="tab-pane leading-relaxed p-2 md:p-5" role="tabpanel" :aria-labelledby="tabs[9].key">
        <TwilioSids :agency-id="agency_info?.id" :twilioSids="twilioSids" :messagingServices="messagingServices" />
      </div>
      <!-- END: Twilio Sids  -->
      <!-- BEGIN: Commissions  -->
      <div v-else-if="currentTab === tabs[10].key" :id="tabs[10].key" class="tab-pane leading-relaxed p-2 md:p-5" role="tabpanel" :aria-labelledby="tabs[10].key">
        <AgencyCommissionsSettings :agency_info="agency_info" />
      </div>
      <!-- END: Commissions  -->
    </div>
  </div>
  <!-- END: Agency View -->
</template>

<script>
import AgencyInfo from "./partials/AgencyInfo.vue";
import AgencyManageBookmarks from "./partials/AgencyManageBookmarks.vue";
import AgencyManageDeadReasons from "./partials/AgencyManageDeadReasons.vue";
import AgencyManageLeadTags from "./partials/AgencyManageLeadTags.vue";
import AgencyNotification from "./partials/AgencyNotification.vue";
import AgencyWorkingHours from "./partials/AgencyWorkingHours.vue";
import AgencyStripeKeys from "@/Components/partials/AgencyStripeKeys.vue";
import AgencyDepartmentListItem from "@/Components/partials/AgencyDepartmentListItem.vue";
import AgencyEmailsAndTexts from "./partials/AgencyEmailsAndTexts.vue";
import TwilioSids from "@/Pages/Agencies/Partials/TwilioSids.vue";
import {usePage} from "@inertiajs/inertia-vue3";
import AgencyCommissionsSettings from "@/Pages/Agencies/Partials/AgencyCommissionsSettings.vue";
import AgencySidebarAndWidgets from "@/Components/partials/AgencySidebarAndWidgets.vue";

export default {
  name: "AppAgencyView",

  components: {
      AgencySidebarAndWidgets,
      AgencyCommissionsSettings,
    TwilioSids,
    AgencyInfo,
    AgencyManageDeadReasons,
    AgencyManageBookmarks,
    AgencyManageLeadTags,
    AgencyWorkingHours,
    AgencyStripeKeys,
    AgencyDepartmentListItem,
    AgencyEmailsAndTexts,
    AgencyNotification,
  },

  props: {
    agency_info: {
      type: Object,
      required: true,
    },

    states: {
      type: Array,
      required: true,
    },

    available_support_persons: {
      type: Array,
      required: true,
    },

    action_schedules: {
      type: Array,
      required: true,
    },

    missed_appt_hours_options: {
      type: Array,
      required: true,
    },

    buckets: {
      type: Array,
      default: () => [],
    },

    months: {
      type: Array,
      default: () => [],
    },

    years: {
      type: Array,
      default: () => [],
    },

    days: {
      type: Array,
      default: () => [],
    },

    slots: {
      type: Array,
      default: () => [],
    },

    hours: {
      type: Object,
      default: null,
    },

    stripe_keys: {
      type: Object,
      default: null,
    },
    sidebar_widgets: {
        type: Object,
        default: null,
    },
    departments: {
      type: Object,
      default: null,
    },
    twilioSids: {
      type: Object,
      default: null
    },
    messagingServices: {
      type: Object,
      default: null
    },
    emailBlastHours: {},
  },

  data() {
    return {
      tabs: [
        { key: 'agency-info', label: 'Agency Info'},
        { key: 'manage-dead-reasons', label: 'Manage Dead Reasons'},
        { key: 'manage-agency-bookmarks', label: 'Manage Agency Bookmarks'},
        //{ key: 'manage-lead-tags', label: 'Manage Lead Tags'},
        { key: 'notification', label: 'Notifications'},
        { key: 'working-hours', label: 'Agency Working Hours'},
        { key: 'stripe-keys', label: 'Stripe Keys'},
        { key: 'departments', label: 'Manage Departments'},
        { key: 'emails-and-texts', label: 'Emails & Texts'},
        { key: 'sidebar-widgets', label: 'Sidebar & Widgets'},
        ...(this.$page.props.auth.is_super_admin ? [{ key: 'twilio-sids', label: "Twilio SID's" }] : []),
        ...(this.$page.props.auth.is_super_admin ? [{ key: 'commissions-settings', label: "Commissions" }] : []),
      ],
      loading: false,
      editMode: true,
      currentTab: 'agency-info',
    }
  },

    mounted() {
    // Read the query parameter from the URL and set the active tab accordingly
    const tabFromUrl = new URL(window.location.href).searchParams.get('tab');

    if (tabFromUrl === 'agency_bookmarks') {
      this.currentTab = tabFromUrl;
    }
  },

  methods: {
    toggleEditMode() {
      this.editMode = ! this.editMode;

      if (this.editMode) {
        this.$nextTick(() => {

        });
      }
    },

    handleShowEditMode(payload) {
      if (payload.fieldId) {
        this.toggleEditMode(payload.fieldId)
      } else {
        this.toggleEditMode();
      }
    },

    setCurrentTab(tabKey) {
      this.currentTab = tabKey;
    },
  },

  computed: {
    isSuperAdmin() {
      return this.$page.props.auth.user.is_super_admin;
    }
  },
}
</script>

<style scoped>
li.active {
  width: unset !important;
}
</style>
